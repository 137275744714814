// Responsive Variables
$extraBig-device: "only screen and (min-width: 1600px)";
$laptop-device: "only screen and (min-width: 1200px) and (max-width: 1599px)";
$desktop-device: "only screen and (min-width: 992px) and (max-width: 1199px)";
$tablet-device: "only screen and (min-width: 768px) and (max-width: 991px)";
$large-mobile: "only screen and (max-width: 767px)";
$small-mobile: "only screen and (max-width: 575px)";
$extra-small-mobile: "only screen and (max-width: 479px)";

.mx-timeline  {
    & .col-md-6 {
        padding-left: 30px;
        padding-right: 30px;
        // Responsive
        @media #{$large-mobile}{
            padding-left: 15px;
            padding-right: 15px;
        }
        @media #{$extra-small-mobile}{
            padding-left: 15px;
            padding-right: 15px;
        }
    }
    & .mx-timeline-list {
        list-style: none;
        position: relative;
        display: inline-block;
        width: 100%;
        padding: 32px 0 65px;
    }
    .item {
        position: relative;
        @media #{$large-mobile}{
            padding-left: 45px;
            width: 100%;
        }
    }
    .item + .item {
        margin-top: 115px;
        // Responsive
        @media #{$large-mobile}{
            margin-top: 30px;
        }
        @media #{$extra-small-mobile}{
            margin-top: 30px;
        }
    }
    & .line {
        position: absolute;
        top: 0;
        left: 50%;
        margin-left: -1px;
        height: 100%;
        border-left: 2px solid #e2e2e8;
        @media #{$large-mobile}{
           left: 15px;
        }
    }
    
    &  .dots {
        position: absolute;
        top: 2px;
        left: 50%;
        transform: translate(-50%,0);
        width: 30px;
        height: 30px;
        color: $primary;
        @media #{$large-mobile}{
            right: auto;
            left: 0;
            transform: none;
        }
        &::before {
            content: '';
            position: absolute;
            border: 1px solid #45c4a0;
            border-radius: 50%;
            top: 0;
            left: 0;
            opacity: .3;
            width: 30px;
            height: 30px;
        }
        & .middle-dot {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            width: 14px;
            height: 14px;
            border-radius: 50%;
            z-index: 2;
            border: 3px solid #45c4a0;
            background: $white;
        }
    }
    
    & .timeline-col {
        
        & .inner {
            text-align: right;
            width: 500px;
            max-width: 100%;
            @media #{$large-mobile}{
                text-align: left;
            }
            .title-wrap{
                .title{
                    font-size: 18px;
                    line-height: 1.3;
                    font-weight: 700;
                    text-transform: uppercase;
                    letter-spacing: 3px;
                    color: $primary;
                    margin-bottom: 28px;
                    // Responsive
                    @media #{$large-mobile}{
                        margin-bottom: 30px;
                    }
                    @media #{$extra-small-mobile}{
                        margin-bottom: 30px;
                    }
                }
            }
            .timeline-image{
                img{
                    border-radius: 5px;
                }
            }
        }
    }
    
    
    & .timeline-info {
        & .inner {
            padding-top: 10px;
            float: right;
            width: 500px;
            max-width: 100%;
            text-align: left;
            @media #{$tablet-device}{
               padding-top: 60px;
            }
            @media #{$large-mobile}{
               padding-top: 30px;
            }
            
        }
        & .timeline-content-body {
            display: inline-block;
            width: 500px;
            max-width: 100%;
        }

        & .title {
            font-size: 28px;
            margin-bottom: 22px;
        }
        & p {
            font-size: 18px;
            line-height: 2;
            @media #{$large-mobile}{
                font-size: 16px;
            }
        }
        
    }
    .item:nth-child(2n+1) {
        .timeline-feature {
            order: 3;
            @media #{$large-mobile}{
                order: -1;
            }
        }
        .timeline-info > .inner {
            float: left;
            text-align: right;
            @media #{$large-mobile}{
                text-align: left;
            }
        }
        .timeline-feature > .inner {
            text-align: left;
            float: right;
        }
        .timeline-feature > .inner {
            text-align: left;
            float: right;
        }
    }
   
}