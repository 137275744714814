// Use this to overwrite Bootstrap and theme variables

// Example of a variable override to change the primary color.
// Remove the "//" to uncomment it and see it in action.
// $primary: #36a6cf;

// Example of a variable override to change the theme font.
// Remove the "//" to uncomment it and see it in action.
// $font-family-sans-serif: "Arial", sans-serif;

// To use a custom font, first add the font files inside folder src/assets/fonts/
// Then use the example below to import your custom font.
// Remove the "//" to uncomment it and see it in action.
// $path-to-fonts: '../fonts/myfont/myfont.css';
// $font-family-sans-serif: "My Font Name", sans-serif;

// To use a Google font use the example below.
// Remove the "//" to uncomment it and see it in action.
// $path-to-fonts: 'https://fonts.googleapis.com/css2?family=Montserrat:ital@0;1&display=swap';
// $font-family-sans-serif: 'Montserrat', sans-serif;

.modal-backdrop {
    --bs-backdrop-bg: rgba(30, 34, 40, 0.6) !important;
}