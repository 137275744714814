//----------------------------------------------------------//
//	COUNTER
//----------------------------------------------------------//
.counter-wrapper {
  p {
    margin-bottom: 0;
    @include font-size($font-size-base);
    font-weight: $font-weight-base;
  }
  .counter {
    @include font-size(2rem);
    letter-spacing: normal;
    line-height: 1;
    margin-bottom: 0.5rem;
    &.counter-lg {
      @include font-size(2.2rem);
    }
  }
  .card-body {
    padding: 1.5rem;
  }
  .icon-bg {
    margin: 0 auto;
  }
}