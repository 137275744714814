// Use this to write your custom SCSS

@import "abstracts/variables";
@import "abstracts/tools";

// Custom class

:root {
    scroll-behavior: smooth;
}

.scalex-n1 {
    transform: scalex(-1);
}


@import "user/solutions-globales";

@import "user/timeline";

@import "user/status-services";

@import 'user/sendinblueForm';




// Card Glass effect

.card {

    &.glass {
        box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2);
        border-radius: 5px;
        background-color: rgba(255, 255, 255, .15);

        backdrop-filter: blur(5px);
    }
}






// Contact form

.contact-form {


    & .select-choices {
        box-shadow: 0 0 1.25rem rgba(30, 34, 40, 0.04);
    }
    
    & .choices__inner {
        display: block;
        width: 100%;
        padding: 0.6rem 1rem;
        height: calc(2.5rem + 2px);
        line-height: 1.25;
        font-size: .75rem;
        text-align: left;
        font-weight: 500;
        color: #60697b;
        background-color: #fefefe;
        background-clip: padding-box;
        border-radius: 0.4rem;
        border: 0;

        transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    }

    & .choices__placeholder {
        color: #959ca9;
        opacity: 1;
    }

    & .choices__list {
        color: #60697b;
        text-align: left;
    }

    .choices[data-type*=select-one]:after {
        content: none;
      }
}

.hide {
    display: none;
}

.hidden {
    display: none;
}

// Cartouch 

.cartouch {
    font-size: 0.7rem;
    font-style: italic;
    margin-top: 30px;
    padding: 0 60px;

    &.home {
        padding: 0px;
    }
}

// Allow required HTML5 native behaviour for choice-js

.choices[data-type*="select-one"] select.choices__input {
    display: block !important;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    left: 0;
    bottom: 0;
}

// A supprimer !!!!!

// .container {

//     background-color: #4cbe7f66;

//         @include media-below (xxl) {
  
//             background-color: rgba(255, 0, 149, 0.712);
//         }

//         @include media-below (xl) {
//             background-color: rgba(55, 0, 255, 0.568);
//         }

//         @include media-below (lg) {
          
//             background-color: rgba(255, 0, 0, 0.678);
//         }

//         @include media-below (md) {
         
//             background-color: rgba(0, 255, 242, 0.678);
//         }

//         @include media-below (sm) {
//             background-color: rgba(229, 255, 0, 0.678);
//         }

//         @include media-below (xs) {
//             background-color: rgba(0, 81, 255, 0.678);
//         }
// }