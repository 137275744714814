@charset "UTF-8";

.input--hidden {
    display: none !important
}

.sib-form-message-panel {
    margin: 0 0 1.25rem 0;
    width: 100%;
    padding: 0.4375rem;
    border: 1px solid;
    display: none
}

.sib-form-message-panel--active {
    display: inline-block
}

.sib-form-message-panel__text {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0.5rem
}

.sib-form-message-panel__text .sib-icon {
    contain: strict;
    display: inline-block;
    fill: currentColor
}

.sib-form-message-panel__text .sib-notification__icon {
    height: 1.5em;
    width: 1.5em;
    flex-shrink: 0;
    margin-right: calc(1rem - 1px)
}

.sib-loader {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px
}

.progress-indicator {
    background: transparent
}

.sib-hide-loader-icon {
    display: none
}

.sib-form .input__button[type=submit] {
    border-bottom-left-radius: 0;
    border-left: 1px solid #c0ccda;
    border-top-left-radius: 0;
    color: inherit;
    margin-left: .5rem
}

.sib-form .entry__error {
    padding: 7px;
    text-align: center;
    font-size: 13px !important;
    color: #ff4949;
    background-color: rgb(255, 228, 226);
    border-radius: 3px;
    border-color: rgb(255, 71, 77);
    line-height: 1rem;
    display: none;
}

.sib-form .clickable__icon {
    height: 1rem;
    width: 1rem;
    fill: #fff;
}

.sib-form .clickable__icon:not(.sib-icon_standalone) {
    margin-left: calc(.7002rem - 1px)
}

.sib-form .progress-indicator {
    -webkit-align-items: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.9);
    display: -webkit-flex;
    display: flex;
    height: 100%;
    -webkit-justify-content: center;
    justify-content: center;
    width: 100%
}

.sib-form .progress-indicator .progress-indicator__icon {
    fill: #0092ff;
    height: 2rem;
    width: 2rem
}

.sib-form .progress-indicator_small .progress-indicator__icon {
    height: 1rem;
    width: 1rem
}

.sib-form .progress-indicator__icon {
    -webkit-animation: indicator-spin 1.3s cubic-bezier(0.46, 0.35, 0.39, 0.85) infinite;
    animation: indicator-spin 1.3s cubic-bezier(0.46, 0.35, 0.39, 0.85) infinite
}

@-webkit-keyframes indicator-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn)
    }
}

@keyframes indicator-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn)
    }
}