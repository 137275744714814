// Hero section

.hero-section {

    & .text-big {
        font-size: 5.5rem; 
        font-weight: bold; 
        text-transform: uppercase;

        @include media-below (md) {
            font-size: 4.5rem;
        }
    }

    p {
        text-wrap: balance;
    }

    & .arrow {
        position: absolute;
        top: -125%;
        right: -30%;

        &>img {
            height: 80px;
            width: 140px;
            transform: rotate(0deg);
        }
    }

    @include media-below (xxl) {
        & .arrow {
            right: -12%;
        }
    }

    @include media-below (xl) {
        & .arrow {
            &>img {
                height: 60px;
                width: 135px;
            }
        }
    }
}



.wizard-container {
    position: relative;
    background: #FEECE9;
    max-width: 500px;
    margin-inline: auto;
    top: 4rem;
}

// Contact form home header

// .card-form-header {
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;

//     & .express--circle {
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         width: 113px;
//         height: 113px;
//         margin-inline: auto;
//         margin-block-start: -80px;
//         border-radius: 50%;
//         background: #FEECE9 !important;

//         & img {
//             width: 70%;
//         }
//     }

//     &>h2 {
//         margin-block-start: -20px;
//     }
// }

.card-form-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & .express--circle {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 113px;
        height: 113px;
        margin-inline: auto;
        margin-block-start: -80px;
        border-radius: 50%;
        // background: #FEECE9 !important;
        background: transparent !important;

        &::before {
            content: '';
            position: absolute;
            border-radius: 50%;
            width: 113px;
            height: 113px;
            background: #FEECE9 !important;
            z-index: 0;
        }

        &::after {
            content: '';
            position: absolute;
            border-radius: 50%;
            width: 113px;
            height: 113px;
            background: #FEECE9 !important;
            box-shadow: 0 0 0 0.05rem rgba(8,60,130,.06), 0 0 1.25rem rgba(30,34,40,.04);
            z-index: -2;

        }


        & img {
            width: 70%;
            z-index: 1;
        }
    }

    &>h2 {
        margin-block-start: -20px;
        z-index: 1;
    }
}

// Contact form home stepper

.progressBar {
    display: flex;
    align-items: center;
    justify-content: center;

    & .progressBar-container {
        display: flex;
        align-items: center;

        & .line {
            width: 22px;
            height: 1px;
            margin-inline: 5px;
            background: var(--bs-primary);
            transition: background 0.5s linear;
        }

        & .circle {
            display: flex;
            justify-content: center;
            align-items: center;
            align-content: center;
            position: relative;
            width: 35px;
            height: 35px;
            border-radius: 50%;
            /* To create a circle shape */
            border: 1px solid var(--bs-primary);
            background-color: transparent;
            /* Initial circle color */

            color: var(--bs-primary);

            transition: background-color 0.5s ease;

            &.active {
                background-color: var(--bs-primary);
                color: var(--bs-white);
            }
        }

    }
}

// Contact form home card buttons


.card-button-title {
    text-align: center;
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
    font-size: 20px;
}

.card-button-container {
    display: flex;
    flex-direction: row;
    column-gap: 1em;

    & .card-button {
        padding: 14px;
        background: #fdf4f2;
        flex: 1;

        border-radius: var(--bs-card-inner-border-radius);
        border: 2px solid transparent;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        container-type: inline-size;

        cursor: pointer;

        transition: all 0.5s ease;

        &:hover {
            // transform: scale(1.1);
            border-color: var(--bs-primary);
        }

        & img {
            width: 80%;
        }



        & .button-title {
            font-size: 16px;
            font-weight: bold;

            @include media-below (sm) {
                font-size: 14px;
            }
        }

    }

}

.radio-buttons-container {
    display: flex;
    column-gap: 1em;
    padding-block-start: 0.5em;

    .custom-radio {
        // background: #ff2f00;
        flex-grow: 1;

        &>input {
            display: none;
        }

        & .radio-btn {
            display: block;
            width: 100%;
            aspect-ratio: 1/1;
            border: 2px solid transparent;
            border-radius: var(--bs-card-inner-border-radius);
            position: relative;
            text-align: center;
            // box-shadow: 0 0 20px #c3c3c367;
            background: #fdf4f2;
            transition: all 0.5s ease;
            cursor: pointer;

            &>i {
                width: 30px;
                height: 30px;
                color: #ffffff;
                background-color:var(--bs-primary);
                font-size: 20px;
                line-height: 25px;
                position: absolute;
                top: -20px;
                left: 0px;
                transform: translateX(-50%) scale(2);
                border-radius: 50px;
                padding: 3px;
                transition: 0.5s;
                pointer-events: none;
                opacity: 0;
            }

            & .hobbies-icon {
                // width: 150px;
                // height: 150px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                row-gap: 1em;
                position: absolute;
                top: 0;
                left: 0;
                // transform: translate(-50%, -50%);

                & img {
                    display: block;
                    width: 75%;
                    // margin-bottom: 20px;

                }

                & i {
                    color: #FFDAE9;
                    line-height: 80px;
                    font-size: 60px;
                }

                & h3 {
                    color: #555;
                    font-size: 16px;
                    font-weight: bold;
                    letter-spacing: 1px;

                    @include media-below (sm) {
                        font-size: 14px;
                    }
                }
            }
        }

        & input {
            &:hover+.radio-btn {
                border: 2px solid var(--bs-primary);
            }
            &:checked+.radio-btn {
                border: 2px solid var(--bs-primary);

                &>i {
                    opacity: 1;
                    transform: translateX(-50%) scale(1);
                }
            }
        }
    }
}

// ChoiceJS

form {

    & .form-check-input:checked {
        background-color: var(--bs-primary);
        border-color: var(--bs-primary);
    }

    & .select-choices {
        box-shadow: 0 0 1.25rem rgba(30, 34, 40, 0.04);
    }
    
    & .choices__inner {
        display: block;
        width: 100%;
        padding: 0.6rem 1rem;
        height: calc(2.5rem + 2px);
        line-height: 1.25;
        font-size: .75rem;
        text-align: left;
        font-weight: 500;
        color: #60697b;
        background-color: #fefefe;
        background-clip: padding-box;
        border-radius: 0.4rem;
        border: 0;

        transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    }

    & .choices__placeholder {
        color: #959ca9;
        opacity: 1;
    }

    & .choices__list {
        color: #60697b;
        text-align: left;
    }

    .choices[data-type*=select-one]:after {
        content: none;
      }

      .choices__list--dropdown, .choices__list[aria-expanded] {
        z-index: 5;
      }
}

// Styling date picker

.datepicker {
    display: flex;
    justify-content: center;
    padding: 1em 1em 1em 1em;

    & .air-datepicker {

        &.-inline- {
            width: 100%;
        }

        & .air-datepicker--navigation {

            & .air-datepicker-nav {

                & .air-datepicker-nav--title {
                    text-align: center;
                }
            }
        }

        & .air-datepicker--content {


            & .air-datepicker-body {

                & .air-datepicker-body--day-names {

                }

                & .air-datepicker-body--cells {

                    &.-days- {


                        & .air-datepicker-cell {

                            &.-disabled- {
                                color: var(--bs-danger);
                                opacity: 0.8;
                            }
                        }
                    }
                }
            }
        }
    }
}

// How it work

.howItWorks {

    display: flex;
    flex-direction: column;
    row-gap: 1em;

    & section {
        width: 100%;
        height: 400px;
        display: grid;
        grid-template-columns: repeat(13, 1fr);
        grid-template-rows: repeat(3, 1fr);
        position: relative;

        // background-color: #4cbe7f66;

        // @include media-below (xxl) {
        //     height: 345px;
        //     background-color: rgba(255, 0, 149, 0.712);
        // }

        // @include media-below (xl) {
        //     background-color: rgba(55, 0, 255, 0.568);
        // }

        // @include media-below (lg) {
        //     height: 300px;
        //     background-color: rgba(255, 0, 0, 0.678);
        // }

        // @include media-below (md) {
        //     height: auto;
        //     background-color: rgba(0, 255, 242, 0.678);
        // }

        // @include media-below (sm) {
        //     background-color: rgba(229, 255, 0, 0.678);
        // }

        // @include media-below (xs) {
        //     background-color: rgba(0, 81, 255, 0.678);
        // }



        & .text-part {
            // background-color: #8a554b96;


            &>p {
                font-size: 22px !important;
                // color: #fff;
                margin: 0px;
            }

            @include media-below (lg) {
                &>p {
                    font-size: 18px !important;
                }
            }

            @include media-below (md) {
                &>p {
                    text-align: center;
                }
            }
        }

        &>span {
            // background-color: #FEECE9;

            width: 5em;
            height: 5em;
        }

        @include media-below (lg) {
            &>span {
                width: 4rem;
                height: 4rem;
            }
        }

        @include media-below (md) {
            &>span {
                width: 3rem;
                height: 3rem;
            }
        }

        &.section1 {

            & .illu-parts {
                grid-area: 1/3/4/7;
                display: flex;
                justify-items: center;
                align-items: center;

                & img {
                    max-width: 465px;
                }
            }

            & .text-part {
                grid-area: 2/8/2/11;
            }

            & .arrow {
                position: relative;

                grid-area: 3/8/4/10;

                &>img {
                    position: absolute;
                    height: 8rem !important;
                    width: 9rem;
                    left: -115px;
                    bottom: -115px;
                    transform: rotate(0deg);
                }
            }

            @include media-below (xxl) {
                & .illu-parts {

                    & img {
                        max-width: 395px;
                    }
                }

                & .text-part {
                    grid-area: 2/8/2/12;
                    ;
                }

                & .arrow {}
            }

            @include media-below (xl) {
                & .illu-parts {

                    & img {
                        max-width: 350px;
                    }
                }

                & .text-part {
                    grid-area: 2/8/2/13;
                    ;
                }

                & .arrow {}
            }

            @include media-below (lg) {
                & .illu-parts {
                    grid-area: 1/1/4/7;

                    & img {
                        max-width: 350px;
                    }
                }

                & .text-part {
                    grid-area: 2/8/2/14;
                }

                & .arrow {

                    &>img {
                        left: -65px;
                        bottom: -100px;
                    }
                }
            }

            @include media-below (md) {

                height: 400px;

                & .illu-parts {
                    grid-area: 1/3/3/12;

                    & img {
                        width: 100%;
                        max-width: 100%;
                    }
                }

                & .text-part {
                    grid-area: 3/2/4/13;

                    display: flex;
                    align-items: center;
                    justify-content: center;

                }

                & .arrow {
                    grid-area: 3/12/4/14;

                    &>img {
                        height: 6rem !important;
                        width: auto;
                        left: -70px;
                        bottom: -150px;
                        transform: rotate(65deg);
                    }
                }
            }

            @include media-below (xs) {

                height: auto;

                & .illu-parts {
                    grid-area: 1/2/3/13;
                }

                & .text-part {
                    grid-area: 3/1/4/14;

                }

                & .arrow {
                    grid-area: -1/12/-1/14;

                    &>img {
                        left: -40px;
                        bottom: -31px;
                        transform: rotate(65deg);
                    }
                }
            }
        }

        &.section2 {

            & .illu-folder {
                grid-area: 1/8/4/10;
                display: flex;
                justify-items: center;
                align-items: center;

                & img {
                    max-width: 215px;
                    width: 100%;
                }
            }

            & .text-part {
                grid-area: 2/4/3/7;

                text-align: right;
            }

            & .arrow {
                position: relative;

                //         bottom: -10%;
                //         right: 35%;
                //         transform: rotate(0);

                grid-area: 3/8/4/10;

                &>img {
                    position: absolute;
                    height: 12rem !important;
                    width: 9rem;
                    left: -130px;
                    bottom: -145px;
                    transform: rotate(60deg);
                }
            }

            @include media-below (xxl) {
                & .illu-folder {}

                & .text-part {
                    grid-area: 2/2/3/7;
                }
            }

            @include media-below (xl) {
                & .illu-folder {}

                & .text-part {
                    grid-area: 2/1/3/7;
                }
            }

            @include media-below (lg) {
                & .illu-folder {
                    grid-area: 1/9/4/12;
                }

                & .text-part {
                    grid-area: 2/1/3/8;

                }

                & .arrow {
                    &>img {
                        height: 9rem !important;
                        left: -75px;
                        bottom: -100px;
                        transform: rotate(65deg);
                    }
                }
            }

            @include media-below (md) {

                height: 355px;

                & .illu-folder {
                    grid-area: 1/5/2/10;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    &>img {
                        max-width: 130px;
                    }
                }

                & .text-part {
                    margin-block-start: 1em;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    grid-area: 2/2/3/13;

                    &>p {
                        text-align: center;
                    }

                }

                & .arrow {
                    grid-area: 3/2/4/4;

                    &>img {
                        height: 6rem !important;
                        left: -60px;
                        bottom: -65px;
                        transform: rotate(-60deg);
                    }
                }
            }

            @include media-below (xs) {

                height: auto;
                max-height: 350px;

                & .illu-folder {
                    grid-area: 1/5/2/10;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    &>img {
                        max-width: 130px;
                    }
                }

                & .text-part {
                    margin-block-start: 1em;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    grid-area: 2/1/3/14;

                    &>p {
                        text-align: center;
                    }

                }

                & .arrow {
                    grid-area: -1/2/-2/4;

                    &>img {
                        height: 5rem !important;
                        left: -70px;
                        bottom: -30px;
                        transform: rotate(-45deg);
                    }
                }
            }
        }

        &.section3 {

            & .illu-logo {
                grid-area: 1/4/4/7;
                display: flex;
                justify-items: center;
                align-items: center;

                & img {
                    max-width: 465px;
                }
            }

            & .text-part {
                grid-area: 2/8/2/11;
            }

            & .arrow {
                position: relative;

                //         bottom: -10%;
                //         right: 35%;
                //         transform: rotate(0);

                grid-area: 3/4/4/5;

                &>img {
                    position: absolute;
                    height: 11rem !important;
                    width: 9rem;
                    left: -105px;
                    bottom: -100px;
                    transform: rotate(-35deg);
                }
            }

            @include media-below (xxl) {

                & .illu-logo {}

                & .text-part {
                    grid-area: 2/8/2/12;
                }
            }

            @include media-below (xl) {

                & .illu-logo {
                    width: 100%;
                }

                & .text-part {
                    grid-area: 2/8/2/14;
                }
            }

            @include media-below (lg) {

                & .illu-logo {
                    width: 100%;
                    grid-area: 1/2/4/7;
                }

                & .text-part {
                    grid-area: 2/8/2/14;
                }

                & .arrow {
                    &>img {
                        height: 8rem !important;
                        width: 9rem;
                        left: -130px;
                        bottom: -85px;
                        transform: rotate(-60deg);
                    }
                }
            }

            @include media-below (md) {

                height: 200px;

                & .illu-logo {
                    grid-area: 1/4/2/11;

                    &>img {
                        max-width: 200px;
                        margin-inline: auto;
                    }
                }

                & .text-part {
                    grid-area: 2/2/2/13;
                }

                & .arrow {
                    grid-area: 3/11/4/13;

                    &>img {
                        height: 5rem !important;
                        width: 5rem;
                        left: -5px;
                        bottom: -40px;
                        transform: rotate(55deg);
                    }
                }
            }

            @include media-below (sm) {

                height: 260px;

                // & .illu-logo {
                //     grid-area: 1/4/2/11;

                //     & > img {
                //         max-width: 200px;
                //         margin-inline: auto;
                //     }
                // }

                // & .text-part {
                //     grid-area: 2/2/2/13;
                // }

                // & .arrow {
                //     grid-area: 3/11/4/13;

                //     &>img {
                //         height: 5rem!important;
                //         width: 5rem;
                //         left: -5px;
                //         bottom: -40px;
                //         transform: rotate(55deg);
                //     }
                // }
            }

            @include media-below (xs) {

                height: auto;
                max-height: 225px;

                & .illu-logo {

                    margin-block-end: 1em;

                }

                & .text-part {
                    grid-area: 2/1/2/14;
                }

                & .arrow {

                    &>img {
                        height: 4rem !important;
                        width: 4rem;
                        left: -20px;
                        bottom: 0px;
                        transform: rotate(55deg);
                    }
                }
            }
        }

        &.section4 {

            height: 500px;

            & .block-container {
                display: flex;
                flex-direction: column;
                row-gap: 1rem;

                grid-area: 1/4/4/10;

                margin-block-start: 3rem;
                margin-inline-start: 1rem;
            }

            & .block1 {

                grid-area: 1/4/4/9;
            }

            & .block2 {
                position: relative;
                display: grid;
                grid-template-columns: repeat(8, 1fr);
                grid-template-rows: repeat(8, 1fr);

                height: 285px;

                grid-area: 2/4/4/9;

                & .text-part {

                    grid-area: 2/2/4/8;

                    &>p {
                        font-size: 37px !important;
                    }
                }

                .parenthese {
                    grid-area: 2/1/3/2;
                    transform: rotate(180deg);
                }

                & .euro-arrow {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    grid-area: 2/8/3/9;

                    .euro {
                        height: 6rem !important;
                    }

                    & .arrow {
                        position: absolute;
                        width: 58px;
                        transform: rotate(-55deg);
                        top: 40px;
                        left: -25px;
                    }
                }

                & .rating-arrow {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    grid-area: 4/3/9/7;

                    .rating {
                        display: block;
                        position: absolute;
                        height: 8rem;
                    }

                    & .arrow {
                        position: absolute;
                        width: 58px;
                        top: -35px;
                        right: 0;
                        transform: rotate(60deg);
                    }
                }




            }

            @include media-below (xxl) {
                & .block-container {
                    grid-area: 1/4/4/11;
                }
            }

            @include media-below (xl) {
                & .block-container {
                    grid-area: 1/4/4/12;
                }
            }

            @include media-below (lg) {
                & .block-container {
                    grid-area: 1/3/4/13;

                    & .block2 {
                        & .text-part {
                            &>p {
                                font-size: 30px !important;
                            }
                        }

                        & .euro-arrow {

                            .euro {
                                height: 5rem !important;
                            }

                            & .arrow {}
                        }

                        & .rating-arrow {

                            grid-area: 4/2/9/6;

                            .rating {}

                            & .arrow {
                                width: 50px;
                            }
                        }
                    }
                }
            }

            @include media-below (md) {
                & .block-container {
                    grid-area: 1/2/4/13;
                    margin-block-start: 1rem;
                    margin-inline-start: 0;

                    & .block1 {
                        & .text-part {
                            max-width: 80%;
                            margin-inline: auto;
                        }
                    }

                    & .block2 {
                        & .text-part {

                            &>p {
                                font-size: 28px !important;
                                text-align: start;
                            }
                        }

                        & .euro-arrow {

                            .euro {
                                height: 4rem !important;
                            }

                            & .arrow {
                                width: 55px;
                                top: 29px;
                                left: -28px;
                            }
                        }

                        & .rating-arrow {

                            grid-area: 4/2/9/6;

                            .rating {}

                            & .arrow {
                                width: 50px;
                                top: -35px;
                                right: -45px;
                                transform: rotate(50deg);
                            }
                        }
                    }
                }
            }

            @include media-below (sm) {
                & .block-container {
                    grid-area: 1/1/4/14;

                    & .block1 {
                        & .text-part {
                            max-width: 80%;
                            margin-inline: auto;
                        }
                    }

                    & .block2 {
                        & .text-part {

                            &>p {
                                font-size: 28px !important;
                                text-align: start;
                            }
                        }

                        & .euro-arrow {

                            .euro {
                                height: 4rem !important;
                            }

                            & .arrow {
                                width: 55px;
                                top: 29px;
                                left: -28px;
                            }
                        }

                        & .rating-arrow {

                            grid-area: 4/2/9/6;

                            .rating {}

                            & .arrow {
                                width: 50px;
                                top: -35px;
                                right: -45px;
                                transform: rotate(50deg);
                            }
                        }
                    }
                }
            }

            @include media-below (xs) {

                height: auto;

                & .block-container {

                    & .block1 {
                        & .text-part {
                            max-width: 80%;
                            margin-inline: auto;
                        }
                    }

                    & .block2 {
                        & .text-part {
                            grid-area: 2/2/4/9;

                            &>p {
                                font-size: 24px !important;
                            }
                        }

                        & .euro-arrow {
                            grid-area: 5/5/9/9;

                            .euro {
                                height: 4rem !important;
                            }

                            & .arrow {
                                width: 50px;
                                top: -55px;
                                right: 35px;
                                left: unset;
                                transform: rotate(18deg);
                            }
                        }

                        & .rating-arrow {

                            grid-area: 4/1/9/5;

                            .rating {
                                height: 6rem;
                            }

                            & .arrow {
                                width: 45px;
                                top: -22px;
                                right: -20px;
                                transform: rotate(50deg);
                            }
                        }
                    }
                }
            }
        }
    }
}


// test

#qbox-container {

    position: relative;

}

#steps-container {
    margin: auto;
    width: 100%;
    min-height: 250px;
    display: flex;
    align-items: flex-start;
}

#q-box__buttons {
    display: flex;
    justify-content: center;
    align-items: center;
}

.step {
    display: none;
    width: 100%;
}

#success {
    display: none;
}

#success h4 {
    color: var(--bs-success);
}

/* PRELOADER */
#preloader-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 1000;
    display: none;
    position: absolute;
    top: 0;
    left: 0;
}

#preloader {
    width: 120px;
    height: 120px;
    border-top-color: #fff;
    border-radius: 100%;
    display: block;
    position: relative;
    top: -30px;
    // left: 50%;
    // margin: -75px 0 0 -75px;
    // -webkit-animation: spin 2s linear infinite;
    // animation: spin 2s linear infinite;
    z-index: 1001;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

#preloader-wrapper {

    & .preloader-section {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
        border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
        background: #feece9;
        z-index: 1000;
    }

}

.loaded #preloader,
.loaded .preloader-section {
    opacity: 0;
    transition: all 0.3s ease-out;
}

.loaded #preloader-wrapper {
    visibility: hidden;
    transform: translateY(-100%);
    transition: all 0.3s 1s ease-out;
}

// Solution avoloi

.card-quote {
    position: absolute;
    right: 65px;
    bottom: -5%;

    @include media-below(md) {
        right: -19px;
        bottom: -28%;
    }
}